import React, { useState } from "react";
import { Modal } from "antd";
import Classes from "../PrivacyPolicy/privacyPolicy.module.scss";

function Terms({ isModalOpen, handleCancel, handleOk }) {
  return (
    <Modal
      className={Classes.PrivacyPolicy}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      title="Terms of Service"
    >
      <p className={Classes.content}>
        Terms of Service By accessing or using{" "}
        <a href="https://thewarriorsdao.com/" target="_blank">
          www.WarriorsDAO.io
        </a>{" "}
        , you agree to be bound by the following terms and conditions.
        <ul>
          <li className="mb-3">
            This site provides information and services related to
            cryptocurrency, blockchain, and digital assets, and is intended for
            educational and informational purposes only.
          </li>
          <li className="mb-3">
            We do not offer financial, legal, or investment advice, and you
            should conduct your own research before engaging in any
            cryptocurrency-related activities.
          </li>
          <li className="mb-3">
            You are solely responsible for complying with applicable laws and
            regulations in your jurisdiction.
          </li>
          <li className="mb-3">
            We are not liable for any losses or damages incurred from the use of
            this site or reliance on any information provided.
          </li>
          <li>Use of this site constitutes acceptance of these terms.</li>
        </ul>
      </p>
      <div className="d-flex justify-content-center align-items-centet gap-3 pt-4 pb-3">
        <button className={Classes.cancelBtn} onClick={handleCancel}>
          Cancel
        </button>
        <button className={Classes.agreeBtn} onClick={handleOk}>
          Accept
        </button>
      </div>
    </Modal>
  );
}

export default Terms;
