import React, { useEffect, useState } from "react";
import Classes from "./protocol.module.scss";
import Icon from "../../assets/images/icons/yellowIcon.svg";
import Overview from "../Overview";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { getPlatformFundsByProject } from "../../api/services/platformFunds";
import moment from "moment";
import { getAllProjects } from "../../api/services/projects";
import { Col, Row, Select } from "antd";
function ProtocolMetrics() {
  const [projects, setProjects] = useState(null);
  const [data, setData] = useState(null);
  const [project, setProject] = useState(null);
  const [activeButton, setActiveButton] = useState("30d");
  const [active, setActive] = useState("gOHM");

  const handleButtonClick = (period) => {
    setActiveButton(period);
  };
  const handleClick = (btn) => {
    setActive(btn);
  };
  const formatProjects = (data) => {
    const formatedData = data.map((item) => {
      return {
        label: item.name,
        value: item._id,
      };
    });

    setProject(formatedData[0]?.value);
    setProjects(formatedData);
  };
  const formatData = (data) => {
    const updatedData = data.map((item) => {
      return {
        month: moment(item?.createdAt).format("DD-MMM HH:mm"),
        value: item?.amount,
      };
    });
    setData(updatedData);
  };
  useEffect(() => {
    const fetchData = async () => {
      const projects = await getAllProjects();
      if (projects?.data?.data?.length > 0) {
        formatProjects(projects.data.data);
      } else {
        setProject(null);
        setProjects(null);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (projects?.length > 0) {
      const fetchData = async () => {
        const res = await getPlatformFundsByProject(project);
        if (res?.data?.data?.length > 0) {
          formatData(res.data.data[0]?.funds);
        } else {
          setData(null);
        }
      };
      fetchData();
    }
  }, [projects, project]);
  const handleChange = (value) => {
    setProject(value);
  };
  return (
    <div className={Classes.protocol}>
      <div className={Classes.content}>
        <div className="d-flex align-items-center gap-2 mb-4 ps-3">
          <img src={Icon} alt="icon" />
          <p className={Classes.title}>Protocol Metrics</p>
        </div>

        <div className={Classes.card}>
          <div className={`mb-4`}>
            <div className={`${Classes.title} mb-2`}>Select Project</div>
            <Select
              value={project}
              style={{
                width: 180,
              }}
              onChange={handleChange}
              options={projects}
            />
          </div>
          <div className={Classes.chartContainer}>
            <div
              className={`${Classes.outer} d-flex align-items-center justify-content-between`}
            >
              {/* <div className={Classes.btnGroup}>
                {["7d", "30d", "90d", "Max"].map((period) => (
                  <button
                    key={period}
                    className={activeButton === period ? Classes.active : ""}
                    onClick={() => handleButtonClick(period)}
                  >
                    {period}
                  </button>
                ))}
              </div> */}
              {/* <div className={`${Classes.btnGroup} ${Classes.btns}`}>
                {["OHM", "gOHM"].map((btn) => (
                  <button
                    key={btn}
                    className={active === btn ? Classes.active : ""}
                    onClick={() => handleClick(btn)}
                  >
                    {btn}
                  </button>
                ))}
              </div> */}
            </div>
            <ResponsiveContainer width="100%" height={300}>
              {data && (
                <LineChart
                  data={data}
                  margin={{ topx: 10, right: 10, left: -10, bottom: 10 }}
                >
                  <XAxis dataKey="month" stroke="#888" />
                  <YAxis stroke="#888" />
                  <Tooltip />
                  <CartesianGrid stroke="#444444" strokeDasharray="3 3" />
                  {/* <Line type="monotone" dataKey="month" stroke="#ffa500" /> */}
                  <Line type="monotone" dataKey="value" stroke="#ff0000" />
                </LineChart>
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtocolMetrics;
