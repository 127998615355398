import React, { useCallback, useContext, useEffect, useState } from "react";
import Classes from "./reward.module.scss";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTimer } from "react-timer-hook";
import * as moment from "moment-timezone";
import Countdown from "react-countdown";
import Button from "../Button";
import { initial } from "lodash";
import { getAllSettings } from "../../api/services/settings";
import SocketContext from "../../context/socket";

function Reward() {
  const [payoutInfo, setPayoutInfo] = useState({
    duration: null,
    enabled: null,
  });
  const socket = useContext(SocketContext);
  const getNextScheduledTime = (frequency, timezone = "America/New_York") => {
    const now = moment().tz(timezone);
    let nextOccurrence;

    switch (frequency) {
      case "daily":

        nextOccurrence = moment.tz(timezone).endOf("day").add(1, "second");
        break;

      case "weekly":

        nextOccurrence = moment.tz(timezone).startOf("isoWeek").add(1, "week");
        break;

      case "monthly":

        nextOccurrence = moment.tz(timezone).startOf("month").add(1, "month");
        break;

      default:
        throw new Error(
          'Invalid frequency specified. Use "daily", "weekly", or "monthly".'
        );
    }

    const duration = moment.duration(nextOccurrence.diff(now));

    const res = `${duration.days()} days, ${duration.hours()} hours, ${duration.minutes()} minutes, ${duration.seconds()} seconds`;
    return duration;
  };
  function getTimeInSeconds(period) {
    const oneDayInSeconds = 24 * 60 * 60; // 1 day = 24 hours * 60 minutes * 60 seconds
    // const oneDayInSeconds = 60 // 1 day = 24 hours * 60 minutes * 60 seconds

    const oneWeekInSeconds = 7 * oneDayInSeconds; // 1 week = 7 days
    const oneMonthInSeconds = 30 * oneDayInSeconds; // Approximate 1 month = 30 days

    switch (period.toLowerCase()) {
      case "daily":
        return oneDayInSeconds;
      case "weekly":
        return oneWeekInSeconds;
      case "monthly":
        return oneMonthInSeconds;
      default:
        console.log("Invalid period. Use 'daily', 'weekly', or 'monthly'.");
      // throw new Error("Invalid period. Use 'daily', 'weekly', or 'monthly'.");
    }
  }
  const formatCountDownTimer = (payoutDuration) => {
    const duration = getNextScheduledTime(payoutDuration,process.env.REACT_APP_DEFAULT_TMEZONE);
    const totalTimeInSeconds = duration.asSeconds();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let obj = {
      duration: !payoutInfo?.enabled ? 0 : totalTimeInSeconds,
      initialRemainingTime: getTimeInSeconds(payoutDuration),
      label: `${days} days`,
    };
    if (!payoutInfo?.enabled) {
      obj.label = `Payout disabled`;
    } else if (days > 0) {
      obj.label = `${days} days`;
    } else if (hours > 0) {
      obj.label = `${hours} hours`;
    } else if (minutes > 0) {
      obj.label = `${minutes} minutes`;
    } else if (seconds > 0) {
      obj.label = `${seconds} seconds`;
    } else {
      return { duration: 0, label: "reward is being distributed" };
    }

    return obj;
  };
  const fetchSettings = async () => {
    try {
      const res = await getAllSettings();
      const payoutStatus = res.data.data.find(
        (item) => item.key === "payoutStatus"
      );
      const payoutDuration = res.data.data.find(
        (item) => item.key === "payoutDuration"
      );
      const status = {
        enabled: true,
        disabled: false,
      };
      setPayoutInfo({
        enabled: status[payoutStatus.value],
        duration: payoutDuration.value,
      });
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);
  useEffect(() => {
    if (socket) {
      socket?.on("payoutSettingsUpdateed", () => {
        fetchSettings();
      });
      return () => {
        socket.off("payoutSettingsUpdateed"); // Ensure you turn off the right event
      };
    }
  }, [socket]);
  const showTime = (duration) => {
    // Example number of seconds
    const totalSeconds = duration; // Change this value to the number of seconds you want to convert
    const days = moment.duration(totalSeconds, "seconds").asDays()
    const hours = moment.duration(totalSeconds, "seconds").asHours();
    const minutes = moment.duration(totalSeconds, "seconds").asMinutes();
    const seconds = moment.duration(totalSeconds, "seconds").asSeconds();

    if (Math.floor(days) > 0) {
      return `${days.toFixed(0)} days`;
    } else if (Math.floor(hours) > 0) {
      return `${hours.toFixed(0)} hours`;
    }else if (Math.floor(minutes) > 0) {
      return `${minutes.toFixed(0)} minutes`;
    }
    else if (Math.floor(seconds) > 0) {
      return `${seconds.toFixed(0)} seconds`;
    }
  };
  return (
    <div className={Classes.reward}>
      <h2 className={`${Classes.title} ${Classes.title2}`}>{payoutInfo&&!payoutInfo?.enabled?"Payout is disabled":"Next Payout in"}</h2>
      <div className="d-flex align-items-center justify-content-center">
        {payoutInfo?.duration && (
          <CountdownCircleTimer
            isPlaying
            duration={
              formatCountDownTimer(payoutInfo?.duration).initialRemainingTime
            }
            initialRemainingTime={
              formatCountDownTimer(payoutInfo?.duration).duration
            }
            colors={["#A30000"]}
            children={formatCountDownTimer(payoutInfo?.duration).label}
            colorsTime={[10]}
            size={230}
            strokeWidth={20}
            rotation="clockwise"
            isGrowing={false}
          >
            {({ remainingTime }) =>
            showTime( remainingTime)
            }
          </CountdownCircleTimer>
        )}
      </div>
    </div>
  );
}

export default Reward;
