import DashboardLayout from "../layout/DashboardLayout";
import MainDashboard from "../components/MainDashboard";
import Investment from "../components/Investment";
import Investors from "../components/Investors";
import YourDashboard from "../components/YourDashboard";
import ProtocolMetrics from "../components/ProtocolMetrics";
import LandingLayout from "../layout/LandingLayout";
import Landing from "../components/Landing";
import Login from "../components/Login";
import Layout from "../layout/SimpleLayout";
import Profile from "../components/Profile";
import Payout from "../components/Payout";
import Withdrawal from "../components/Withdrawal";
import DaoFlowChart from "../components/DaoFlowChart";
export const routes = [
  {
    path: "/",
    access: true,
    exact: true,
    title: "landing",
    layout: LandingLayout,
    component: Landing,
  },
  {
    path: "/login",
    access: true,
    exact: true,
    title: "login",
    layout: Layout,
    component: Login,
  },
  {
    path: "/dashboard",
    access: false,
    exact: true,
    title: "dashboard",
    layout: DashboardLayout,
    component: MainDashboard,
  },
  {
    path: "/your-dashboard",
    access: false,
    exact: true,
    title: "your-dashboard",
    layout: DashboardLayout,
    component: YourDashboard,
  },
  {
    path: "/protocol-metrics",
    access: false,
    exact: true,
    title: "protocol-metrics",
    layout: DashboardLayout,
    component: ProtocolMetrics,
  },
  {
    path: "/investors",
    access: false,
    exact: true,
    title: "investors",
    layout: DashboardLayout,
    component: Investors,
  },
  {
    path: "/investment",
    access: false,
    exact: true,
    title: "investment",
    layout: DashboardLayout,
    component: Investment,
  },
  {
    path: "/profile",
    access: false,
    exact: true,
    title: "profile",
    layout: DashboardLayout,
    component: Profile,
  },
  {
    path: "/payout",
    access: false,
    exact: true,
    title: "payout",
    layout: DashboardLayout,
    component: Payout,
  },
  {
    path: "/withdrawal",
    access: false,
    exact: true,
    title: "withdrawal",
    layout: DashboardLayout,
    component: Withdrawal,
  },
  {
    path: "/flowChart",
    access: false,
    exact: true,
    title: "flowChart",
    layout: DashboardLayout,
    component: DaoFlowChart,
  },
];
